import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>

<MDXTag name="p" components={components}>{`HealthShare's documented personality and voice forms the foundation of its brand. It should accurately reflect our mission and culture. Our goal is to ensure that this comes through in all forms of public-facing communication.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Personality`}</MDXTag>
<MDXTag name="p" components={components}>{`Personified, HealthShare is; approachable, confident, helpful and informative. Our communication is friendly, respectful, professional and matter-of-fact. HealthShare does not appear as sterile, corporate, silly, clichéd or arrogant.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Audience`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Patients, carers and consumers`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`GPs, specialists and allied practitioners`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Pharmaceutical industry, health-focused corporations, not-for-profit and government organisations`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Private health insurers and primary health networks`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`It is important not to talk to one customer directly while ignoring or commoditising another. Explain features and benefits in a way that appeals to all, when possible.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Writing style`}</MDXTag>
<MDXTag name="p" components={components}>{`HealthShare's vocabulary is to-the-point and in plain language. We use an intuitive, formal—but not sterile—tone to communicate information clearly. We craft messages in an optimistic way.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Case`}</MDXTag>
<MDXTag name="p" components={components}>{`Use sentence-case for headings, buttons and menu items. ALL CAPS should be avoided in almost every situation; it is aggressive, harder to read and prevents trademarked words from being written correctly.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Software alerts`}</MDXTag>
<MDXTag name="p" components={components}>{`When alerting a user to an error in form submission or something wrong on our end, use optimistic copy and never blame the user.`}</MDXTag>
<MDXTag name="p" components={components}>{`For example:`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Please enter a password that is at least twelve characters.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Instead of:`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Your password is invalid. Enter a password that is twelve characters or more.`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  